import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  DescriptionTitle,
  LettersWrapper,
  Image,
  SlideTransitionWrapper,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { FluidImageType } from "../../../../../../utils/types";
import { PageTitle } from "../../../../../common/PageTitle";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";

interface LettersMobilesProps {
  pageChange: PageChange;
}

const LettersMobile: React.FC<LettersMobilesProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(lettersQuery);
  const nOfSlides = 3;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  const renderOkeLetters = () =>
    data.allFile.nodes.map((img: FluidImageType, index: number) => (
      <Image key={index} fluid={img.childImageSharp.fluid} />
    ));

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        const newSlide = slide - 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        const newSlide = slide + 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`values_title`)} />
      <PageWrapper animation={pageChange}>
        <Title colorFirstLetter={1}>{t(`letters_o`)}</Title>
        <Title colorFirstLetter={1}>{t(`letters_k`)}</Title>
        <Title colorFirstLetter={1}>{t(`letters_e`)}</Title>
        <SlideTransitionWrapper slideChange={slideTransition}>
          {slide === 0 ? (
            <Description>{t(`letters_description`)}</Description>
          ) : slide === 1 ? (
            <Description>
              <DescriptionTitle>{t(`letters_o`)}</DescriptionTitle>
              {t(`o_description`)}
            </Description>
          ) : slide === 2 ? (
            <Description>
              <DescriptionTitle>{t(`letters_k`)}</DescriptionTitle>
              {t(`k_description`)}
            </Description>
          ) : (
            <Description>
              <DescriptionTitle>{t(`letters_e`)}</DescriptionTitle>
              {t(`e_description`)}
            </Description>
          )}
        </SlideTransitionWrapper>
        <LettersWrapper>{renderOkeLetters()}</LettersWrapper>
        <ArrowsWrapper>
          <LeftArrowWrapper
            slideChange={slide === 1 ? slideTransition : undefined}
          >
            {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
          </LeftArrowWrapper>
          <RightArrowWrapper
            slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
          >
            {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
          </RightArrowWrapper>
        </ArrowsWrapper>
      </PageWrapper>
    </>
  );
};

const lettersQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "okeLetters" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default LettersMobile;
